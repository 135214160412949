import {useEffect, useMemo, useRef, useState} from "react";
import {COLECTIVOS} from "../../../services/constants";
import {Colectivo} from "../../../config/types";

export const useGetPositions = (linea: string | number) => {
    // read websocket and return response via state colectivosLive
    const [colectivosLive, setColectivosLive] = useState<Colectivo[]>([]);
    const [error, setError] = useState<any>(null);

    const obj = useMemo(() => ([
        {
            provincia: "0",
            linea: linea.toString(),
        }
    ]), [linea]);

    const ws = useRef<WebSocket>();

    const openWS = () => {
        if (!ws || (ws.current && ws.current?.readyState !== WebSocket.CLOSED))
            return;
        // check if i should use wss or ws
        const isSecure = window.location.protocol === 'https:';
        const host = isSecure ? COLECTIVOS.websocketGetColectivosSecure : COLECTIVOS.websocketGetColectivos;
        ws.current = new WebSocket(host);
        ws.current.onopen = () => {
            console.log('Connected to websocket');
            // Envio mensaje al websocket con la linea de colectivo
            ws.current?.send(JSON.stringify(obj));
        };
        ws.current.onmessage = (message) => {
            try {
                let data = JSON.parse(message.data);
                if (data[0] == null) data = [];
                setColectivosLive(data);
            } catch (error) {
                setError(error);
            }
        };

        ws.current.onclose = () => {
            console.log('Disconnected from websocket');
            // try to recconnect
        };
    }

    useEffect(() => {
        openWS();
        return () => {
            if (ws.current?.readyState === WebSocket.OPEN)
                ws.current?.close();
        };
    }, [ws]);

    // Si cambia la linea de colectivo, envio un mensaje al websocket
    useEffect(() => {
        // Valido que el socket este abierto
        console.log('status ws: ', ws.current?.readyState)
        if (ws.current?.readyState !== WebSocket.OPEN) {
            openWS();
            return
        }
        ;
        ws.current.send(JSON.stringify(obj));
    }, [obj]);

    return {colectivosLive, error};

}
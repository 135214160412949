import React from 'react';
import autobu from '../../../../../assets/autobusm.png';
import arrowDown from '../../../../../assets/arrow_down.png';
import styles from './styles.module.scss';
import {Colectivo} from "../../../../../config/types";

interface IconContentProps {
    markerStyle: React.CSSProperties;
    arrowStyle: React.CSSProperties;
    colectivo: Colectivo;
}

const IconContent: React.FC<IconContentProps> = ({ markerStyle, arrowStyle, colectivo }) => (
    <div className={styles.bondiContainer}>
        <img className={styles.marker} style={markerStyle} alt="Bus" src={autobu} />
        <div className={styles.arrowContainer} style={arrowStyle}>
            <img className={styles.arrow} alt="Bus" src={arrowDown} />
        </div>
    </div>
);

export default IconContent;
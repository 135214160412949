import React from 'react';
import {Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import {Colectivo} from '../../../config/types';
import IconContent from './components/IconContent';
import styles from './styles.module.scss';
import {LineaRamalColor} from "../Map/types";
import Button from "@mui/material/Button";
import {PAGES} from "../Map/constants";

interface Params {
    lat: number;
    lng: number;
    key: string;
    orientation: number;
    colectivo: Colectivo;
    uniqueFullLineListWColor: LineaRamalColor[];
    setTrackingVehicle?: (value: string) => void;
}

const ColectivoMarker = ({lat, lng, setTrackingVehicle, key, orientation, colectivo, uniqueFullLineListWColor}: Params) => {
    const color = uniqueFullLineListWColor.find((v) => v.lineaRamal === colectivo.linea + colectivo.ramal)?.color ?? 'black';
    const markerStyle = { backgroundColor: color };
    const arrowStyle = {transform: `rotate(${-orientation - 90}deg)`};

    const iconHtml = ReactDOMServer.renderToString(
        <IconContent markerStyle={markerStyle} arrowStyle={arrowStyle} colectivo={colectivo}/>
    );

    const icon = L.divIcon({
        html: iconHtml,
        className: '',
        iconSize: [25, 25],
    });

    const informacionColectivo = () => {
        // convert last_leido timestamp to date
        const date = (new Date(colectivo.last_leido * 1000));
        // convert date to dd/mm/yyyy hh:mm:ss
        const dateS = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        return (
            <>
                Linea: {colectivo.linea}<br />
                Ramal: {colectivo.ramal}<br />
                Interno: {colectivo.interno}<br />
                Velocidad: {colectivo.velocidad.toFixed(2)} km/h<br />
                Ultima actualizacion: {dateS}
                <br />
                {setTrackingVehicle && <Button onClick={() => setTrackingVehicle(colectivo.interno)}
                                                                   variant="contained" className={styles.buttonFollow}
                                                                   color="primary">Ver recorrido realizado</Button>}
            </>
        );
    }

    return (
        <Marker position={[lat, lng]} icon={icon}
                eventHandlers={{}}>
            <Popup>
                <div>
                    <h3>Informacion de colectivo</h3>
                    <p>{informacionColectivo()}</p>
                </div>
            </Popup>
        </Marker>
    );
};

export default ColectivoMarker;
import {useGetPositions} from "../../../../../hooks/react-query/colectivos/useListaColectivos";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "./styles.module.scss";
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";

interface Params {
    addFavorite: (line: string) => void;
    removeFavorite: (line: string) => void;
    onClose: () => void;
    favorites: string[];
}

export default function AddNewFavorites({addFavorite, removeFavorite, onClose, favorites}: Params) {
    const {lineas, isLoading} = useGetPositions();
    const [filter, setFilter] = React.useState("");

    const filteredLineas = Object.entries(lineas?.[0] ?? {}).filter(([linea, _]) => linea.includes(filter));

    if (isLoading) return <p>Cargando...</p>;
    // Map all the lines as chips in a list, also allow to filter them via a textfield, all the components are from Material UI
    return (
        <div>
            <h1>Agregar linea favorita</h1>
            <p>Seleccioná una linea de colectivo para agregar a tus favoritos</p>
            <hr/>
            <TextField
                label="Buscar linea"
                fullWidth
                value={filter}
                color="info"
                sx={{
                    input: {color: "white"},
                    label: {color: "white"},
                }}
                variant="outlined"
                className={styles.searchField}
                onChange={(e) => setFilter(e.target.value)}
            />
            {filteredLineas.length > 0 && <Box className={styles.chips}>
                {filteredLineas.map(([linea, _]) => (
                    <Chip
                        key={linea}
                        className={styles.chip}
                        label={linea}
                        icon={favorites.includes(linea) ? <DeleteIcon/> : <AddIcon/>}
                        color="primary"
                        onClick={() => favorites.includes(linea) ? removeFavorite(linea) : addFavorite(linea)}
                        style={{color: "white"}}
                    />
                ))}
            </Box>
            }
            {filteredLineas.length === 0 &&
                <Alert severity="warning" className={styles.noResults}>No se encontraron resultados</Alert>}
            <div className={styles.buttonCloseContainer}>
                <Button onClick={onClose} variant="contained" className={styles.buttonClose}
                        color="primary">Cerrar</Button>
            </div>
        </div>
    );
}
import {COLECTIVOS} from "../../../services/constants";
import {useQuery} from "react-query";
import {colectivos} from "../../../services";
import * as pako from "pako";
import {VivoBondiItem} from "../../../config/types";

export const useGetPositions = () => {
    const query = useQuery(COLECTIVOS.listaColectivosVivo, () => colectivos.listaColectivosVivo());

    let lineas: VivoBondiItem[] | undefined = [];
    if (query.data) {
        const d = (new TextDecoder("ISO-8859-1"));
        const data = d.decode(query.data.data);
        lineas = [{...JSON.parse(data)[0]}, JSON.parse(data)[1]];
    }

    return {...query, lineas};
}
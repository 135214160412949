import React, {useEffect, useState} from "react";
import {Marker, Popup} from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import IconContent from "../ColectivoMarker/components/IconContent";
import L from "leaflet";
import styles from './styles.module.scss'

export default function MyLocation() {
    // My location pin component for react-leaflet
    // ASk for location permission
    const [location, setLocation] = useState<{ latitude: number, longitude: number } | null>(null);

    const success = (position: any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({latitude, longitude});
    }

    const error = (error: any) => {
        console.log(error);
    }

    // update the location every 5 seconds
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (location != null && interval === undefined)
            interval = setInterval(() => {
                navigator.geolocation.getCurrentPosition(success, error);
            }, 1000);
        return () => clearInterval(interval);
    }, [location]);


    if (location === null)
        navigator.geolocation.getCurrentPosition(success, error);

    if (location === null)
        return null;


    const iconHtml = ReactDOMServer.renderToString(
        <div className={styles.pin}/>
    );

    const icon = L.divIcon({
        html: iconHtml,
        className: '',
        iconSize: [25, 25],
    });


    // render the pin by marker
    return (
        <Marker position={[location.latitude, location.longitude]} icon={icon}/>
    )
}
import Modal from "@mui/material/Modal";
import {useEffect, useState} from "react";
import FavLineSelector from "../FavLineSelector";
import AddNewFavorites from "../AddNewFavorites";
import styles from "./styles.module.scss";

interface Params {
    open: boolean;
    onClose: () => void;
    onSelect: (line: string) => void;
    favorites: string[];
    setFavorites: (favorites: string[]) => void;
}

enum OPENED_STATUS {
    OPENED_FAVORITES,
    OPENED_NEW_FAV
}

export default function ModalSelector({open, onClose, onSelect, favorites, setFavorites}: Params) {
    const [whatOpen, setWhatOpen] = useState<OPENED_STATUS>(OPENED_STATUS.OPENED_FAVORITES);

    useEffect(() => {
        setWhatOpen(OPENED_STATUS.OPENED_FAVORITES);
    }, [open]);

    const deleteFavorite = (line: string) => {
        // Open modal to confirm delete

        setFavorites(favorites.filter((f) => f !== line));
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className={styles.boxModal}>
                {whatOpen === OPENED_STATUS.OPENED_FAVORITES &&
                    <FavLineSelector onSelect={onSelect} favorites={favorites} onDelete={deleteFavorite}
                                     openAddFavorite={() => setWhatOpen(OPENED_STATUS.OPENED_NEW_FAV)}/>}
                {whatOpen === OPENED_STATUS.OPENED_NEW_FAV &&
                    <AddNewFavorites addFavorite={(e) => setFavorites([...favorites, e])}
                                     favorites={favorites ?? []}
                                     removeFavorite={deleteFavorite}
                                     onClose={() => setWhatOpen(OPENED_STATUS.OPENED_FAVORITES)}/>}
            </div>
        </Modal>
    )
}
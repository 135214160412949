import React, {useEffect, useState} from "react";
import {useGetPositions} from "../../../hooks/react-query/colectivos/useGetPositions";
import ColectivoMarker from "../ColectivoMarker";
import Favorite from "../Favorite";
import {CreatePolylines} from "../Routes/polylineUtilsRoutes";
import {COLORS, PAGES} from "./constants";
import {AlertInfo, LineaRamalColor} from "./types";
import Alert from "@mui/material/Alert";
import styles from './styles.module.scss'
import {MapContainer, TileLayer} from "react-leaflet";
import MyLocation from "../MyLocation";
import ColectivoRoute from "../ColectivoRoute";
import Button from "@mui/material/Button";

export default function Map() {
    const [currentPage, setCurrentPage] = useState<string>(PAGES.LIVE_MAP);
    const [trackedVehicle, setTrackedVehicle] = useState<string>("");
    const [selected, setSelected] = useState<string>(localStorage.getItem("lastSelected") ?? "");
    const {colectivosLive, error} = useGetPositions(selected ?? "");
    const [alertData, setAlertData] = useState<AlertInfo>({enabled: false, severity: "success", children: 'hola'})

    // Linea + ramal de colectivos live
    const uniqueFullLineList = colectivosLive.map(colectivo => colectivo.linea + colectivo.ramal).filter((value, index, self) => self.indexOf(value) === index);

    // Color added by index
    const uniqueFullLineListWColor: LineaRamalColor[] = uniqueFullLineList.map((v, index) => ({
        lineaRamal: v,
        color: COLORS[index]
    }));

    useEffect(() => {
        if (alertData.enabled) {
            setTimeout(() => setAlertData({...alertData, enabled: false}), 5000);
        }
    }, [alertData]);

    useEffect(() => {
        if (error) {
            setAlertData({enabled: true, severity: 'error', children: 'Error al cargar la información.'});
        }
    }, [error]);

    useEffect(() => {
        if (uniqueFullLineListWColor.length === 0) {
            setAlertData({enabled: true, severity: 'warning', children: 'No hay colectivos disponibles para mostrar.'});
        }
    }, [colectivosLive]);

    const trackVehicle = (interno: string) => {
        setCurrentPage(PAGES.TRACKING);
        setTrackedVehicle(interno);
    }


    return (
        <div className={styles.container}>
            <MapContainer
                center={[-34.6037, -58.3816]}
                zoom={11}
                style={{height: '100vh', width: '100%'}}
            >
                <>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {currentPage === PAGES.LIVE_MAP && (
                        <>
                            {colectivosLive.map((colectivo, index) =>
                                <ColectivoMarker uniqueFullLineListWColor={uniqueFullLineListWColor}
                                                 orientation={colectivo?.orientacion} key={String(index)}
                                                 colectivo={colectivo}
                                                 setTrackingVehicle={trackVehicle}
                                                 lat={colectivo?.posicion.latitud} lng={colectivo?.posicion.longitud}/>
                            )}
                            <CreatePolylines uniqueFullLineListWColor={uniqueFullLineListWColor}/>
                        </>
                    )}
                    {currentPage === PAGES.TRACKING && (
                        <>
                            <ColectivoMarker uniqueFullLineListWColor={uniqueFullLineListWColor}
                                             orientation={colectivosLive.find(colectivo => colectivo.interno === trackedVehicle)?.orientacion ?? 0}
                                             key={String(0)}
                                             colectivo={colectivosLive.find(colectivo => colectivo.interno === trackedVehicle) ?? {} as any}
                                             lat={colectivosLive.find(colectivo => colectivo.interno === trackedVehicle)?.posicion.latitud ?? 0}
                                             lng={colectivosLive.find(colectivo => colectivo.interno === trackedVehicle)?.posicion.longitud ?? 0}/>

                            <ColectivoRoute uniqueFullLineListWColor={uniqueFullLineListWColor}
                                            colectivo={colectivosLive.find(colectivo => colectivo.interno === trackedVehicle) ?? {} as any}/>
                        </>
                    )}

                    <MyLocation/>
                </>
            </MapContainer>
            {currentPage === PAGES.LIVE_MAP && (
                <>
                    <Favorite selected={selected} setSelected={setSelected} activeUnits={colectivosLive.length}/>
                </>
            )}
            {currentPage === PAGES.TRACKING && (
                <>
                    <Button onClick={() => setCurrentPage(PAGES.LIVE_MAP)}
                            variant="contained" className={styles.buttonBack}
                            color="primary">Volver al mapa en vivo</Button>
                </>
            )}
            {
                alertData.enabled && (
                    <Alert className={styles.alert} {...alertData}/>
                )
            }
        </div>
    );
};
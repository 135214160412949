// color list that contrast with white mode of maps and black mode at least 15 if there are more last will be used
export const COLORS = [
    '#E63946', // Red
    '#F1FAEE', // Light Green
    '#A8DADC', // Teal
    '#457B9D', // Blue
    '#1D3557', // Dark Blue
    '#F46737', // Orange
    '#9C66BA', // Purple
    '#EDC988', // Yellow
    '#2A9D8F', // Green
    '#E9C46A', // Yellow Orange
    '#F4D03F', // Bright Yellow
    '#D35400', // Orange Red
    '#363062', // Dark Purple
    '#1C0001', // Dark Red
    '#C70039', // Crimson
    '#FF5733', // Red Orange
    '#FFC300', // Gold
    '#DAF7A6', // Light Green 2
    '#90BE6D', // Olive Green
    '#54228B', // Purple 2
];

export enum PAGES {
    LIVE_MAP = 'LIVE_MAP',
    TRACKING = 'TRACKING',
}
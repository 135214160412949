import {useEffect, useState} from "react";
import Chip from "@mui/material/Chip";
import styles from "./styles.module.scss";
import FavLineSelector from "./components/FavLineSelector";
import ModalSelector from "./components/ModalSelector";
import InfoComponent from "./components/InfoComponent";

interface Params {
    selected: string | undefined;
    setSelected: (selected: string) => void;
    activeUnits: number;
}

/** Component that uses chips from Material UI to display the favorite colectivos, the favorites are stored in the local storage */
export default function Favorite({selected, setSelected, activeUnits}: Params) {
    let initState = JSON.parse(localStorage.getItem("favorites") ?? "[]") as string[];

    const [favorites, setFavorites] = useState<string[]>(initState);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (!favorites) return;
        localStorage.setItem("favorites", JSON.stringify(favorites));
    }, [favorites]);

    useEffect(() => {
        // if not selected, select the last selected from local storage
        if (!selected) {
            const lastSelected = localStorage.getItem("lastSelected");
            if (lastSelected) {
                setSelected(lastSelected);
            }
        } else {
            localStorage.setItem("lastSelected", selected);
        }
    }, [selected]);

    return (
        <div className={styles.container}>
            <InfoComponent selectedLinea={selected ?? ''} activeUnits={activeUnits}/>
            <div className={styles.changeLine} onClick={() => setOpen(true)}>
                <p>Cambiar linea</p>
            </div>
            <ModalSelector open={open} onClose={() => setOpen(false)} onSelect={(line) => {
                setSelected(line);
                setOpen(false);
            }} setFavorites={setFavorites} favorites={favorites}/>
        </div>
    );
}
import React from 'react';
import { Polyline, CircleMarker, Popup } from 'react-leaflet';
import { Colectivo } from '../../../config/types';
import { LineaRamalColor } from '../Map/types';

interface Props {
    colectivo: Colectivo;
    uniqueFullLineListWColor: LineaRamalColor[];
}

const ColectivoRoute = ({ colectivo, uniqueFullLineListWColor }: Props) => {
    const color = uniqueFullLineListWColor.find((v) => v.lineaRamal === colectivo.linea + colectivo.ramal)?.color ?? 'black';

    return (
        <>
            <Polyline
                positions={colectivo.recorrido_realizado.posiciones.map(pos => [pos.latitud, pos.longitud])}
                pathOptions={{ color }}
            />
            {colectivo.recorrido_realizado.posiciones.map((pos, index) => (
                <CircleMarker
                    key={index}
                    center={[pos.latitud, pos.longitud]}
                    radius={5}
                    color={color}
                >
                    <Popup>
                       Pasó por aquí: {new Date(pos.timestamp * 1000).toLocaleTimeString()}
                    </Popup>
                </CircleMarker>
            ))}
        </>
    );
};

export default ColectivoRoute;
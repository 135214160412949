import './App.css';
import Map from "../Colectivos/Map";
import {QueryClient, QueryClientProvider} from 'react-query'

function App() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <Map/>
            </div>
        </QueryClientProvider>
    );
}

export default App;

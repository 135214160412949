import shapesBondi from "../../../assets/shape_bondi.json"
import {LineaRamalColor} from "../Map/types";
import {Polyline} from "react-leaflet";

// shapesBondi is a list with objects route_short_name (nombre completo linea) latitud Number[] longitud Number[]

interface Props {
    uniqueFullLineListWColor: LineaRamalColor[];
}

interface ShapesBondi {
    route_short_name: string;
    route_id: string;
    shape_id: number;
    latitud: number[];
    longitud: number[];
}


export function CreatePolylines({uniqueFullLineListWColor}: Props) {
    // Creo un array de PolylineOptions para cada linea de colectivo activa con un color distinto:
    let polylineOptionsList: any = uniqueFullLineListWColor.reduce((acc: any[], value, index) => {
        // Find all shapes that match the route_short_name
        const shapes = (shapesBondi as ShapesBondi[]).filter((shape) => shape.route_short_name === value.lineaRamal);
        if (shapes.length === 0) return acc;

        // Create polyline options for each shape
        shapes.forEach((shape, index) => {
            const positions = shape.latitud.map((latitud: number, index: number) => ([latitud, shape.longitud[index]]));
            acc.push({
                positions,
                name: value.lineaRamal,
                key: `${value.lineaRamal}-${shape.shape_id}-${index}`,
                pathOptions: {
                    color: value.color,
                }
            });
        });

        return acc;
    }, []);

    // remove null values
    polylineOptionsList = polylineOptionsList.filter((value: any) => value);

    // Creo las polylines en el mapa
    return (<>{
            polylineOptionsList.map((options: any) => {
                // return Polilyne jsx
                return (<Polyline {...options}/>)
            })
        } </>
    );
}
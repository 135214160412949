import styles from "./styles.module.scss";

interface Params {
    selectedLinea: string;
    activeUnits: number;
}

export default function InfoComponent({selectedLinea, activeUnits}: Params) {


    return (
        <div className={styles.containerInfo}>
            <p className={styles.title}>Linea seleccionada <span className={styles.linea}>{selectedLinea}</span></p>
            <p className={styles.activos}>Unidades activas {activeUnits}</p>
        </div>
    )
}
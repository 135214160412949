import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import styles from "./styles.module.scss";
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';


/** Component that open mui modal and let the user select a line from the favorites */

interface Params {
    onSelect: (line: string) => void;
    favorites: string[];
    openAddFavorite: () => void;
    onDelete: (line: string) => void;
}

export default function FavLineSelector({onSelect, onDelete, favorites, openAddFavorite}: Params) {
    const [actualLine, setActualLine] = React.useState<string | null>(localStorage.getItem("lastSelected"));
    return (
        <>
            <h1>Lineas favoritas</h1>
            <p>Hacé click en la linea que desees ver, hace click en el icono de basura para eliminarla de favoritos. Si
                no tenes la linea que buscas
                agregada aqui podes añadirla haciendo click en "Agregar linea favorita".</p>
            <hr className={styles.hr}/>
            {favorites.length === 0 && <Alert severity="info">No tenes lineas favoritas</Alert>}
            <Chip className={styles.chipAdd} label="Agregar linea favorita" color="primary" onClick={openAddFavorite}/>

            <Box className={styles.chips}>
                {favorites.map((favorite, index) => (
                    <div className={styles.chip} key={String(index)}>
                        <Radio
                            key={String(index)}
                            value={favorite}
                            checked={actualLine === favorite}
                            name="radio-button-fav-sel"
                            onClick={() => {
                                setActualLine(favorite);
                                onSelect(favorite);
                            }}
                            color="primary"
                        />
                        {favorite}
                        <DeleteIcon onClick={() => onDelete(favorite)}/>
                    </div>
                ))}
            </Box>
        </>

    );


}